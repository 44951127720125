import * as css from '@adobe/css-tools';
import WPSLog from "../utils/console";
import { FLIPPERS, Flippers } from '../constants/flippers';

function isolateStyle(fragment, namespaceId, config) {
    const styleElements = fragment.querySelectorAll("style");
    if (styleElements) {
        styleElements.forEach((styleElement) => {
            styleElement.innerHTML = namespaceCssRules(
                styleElement.innerHTML,
                namespaceId,
                config
            );
        });
    }
}

function namespaceCssRules(style, namespaceId, config) {
    try {
        const obj = css.parse(style);
        obj.stylesheet.rules.forEach(
            rule => {
                if (rule.selectors) {
                    return _prefixRule(rule, namespaceId, config);
                }

                if (rule.rules) {
                    rule.rules.forEach(_rule => _prefixRule(_rule, namespaceId, config));
                    return rule;
                }
            }
        );
        const finalCss = css.stringify(obj, { compress: false });
        WPSLog.info("namespaced css:" + finalCss);
        return finalCss;
    } catch (e) {
        console.error(`error during css namespacing. context: ${namespaceId}, exception: ${e.stack}`);
        return style;
    }
}

function _prefixRule(rule, namespaceId, config) {
    const prefix = `[data-wps-namespace-id=${namespaceId}]`;

    if (rule.selectors) {
        if (Flippers.isFlipperOn(config, FLIPPERS.ALLOW_PARENT_ELEMENT_STYLING)) {
            rule.selectors = rule.selectors.map(selector => `${prefix}${selector}, ${prefix} ${selector}`);
        } else {
            rule.selectors = rule.selectors.map(selector => `${prefix} ${selector}`);
        }
    }
    return rule;
};

export default { isolateStyle, namespaceCssRules };
